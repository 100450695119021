import { axiosInstance } from 'libs/api'
import ApiData from 'models/ApiData'
import ApiError from 'models/ApiError'

const BASEURL = process.env.REACT_APP_API_BASEURL

class GoogleAnalytics {
  static async runRealtimeReport (payload: any) {
    try {
      const resp = await axiosInstance.post(`${BASEURL}/reports/google-analytics/run-realtime-report`, payload)
      return ApiData.fromResponse(resp)
    } catch (err: any) {
      throw new ApiError(err)
    }
  }

  static async runReport (payload: any) {
    try {
      const resp = await axiosInstance.post(`${BASEURL}/reports/google-analytics/run-report`, payload)
      return ApiData.fromResponse(resp)
    } catch (err: any) {
      throw new ApiError(err)
    }
  }
}

export default class ReportsService {
  static GoogleAnalytics = GoogleAnalytics
}
