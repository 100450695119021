import { Card, Col, Row, Statistic } from 'antd'
import useGoogleAnayticsReport from 'hooks/useGoogleAnayticsReport'

export default function RecentAnalytics () {
  const { data } = useGoogleAnayticsReport({
    metrics: [
      { name: 'activeUsers' },
      { name: 'totalUsers' },
      { name: 'screenPageViews' },
      { name: 'bounceRate' }
    ],
    dateRanges: [{
      startDate: '7daysAgo',
      endDate: 'today'
    }]
  })

  const realtimeData = data?.data || {}

  console.log(realtimeData)

  return (
    <Card title="Performance in 7 days">
      <Row gutter={16}>
        <Col span={6}>
          <Statistic title="Active Users" value={realtimeData?.rows?.[0]?.metricValues?.[0]?.value || 0} />
        </Col>
        <Col span={6}>
          <Statistic title="Total Users" value={realtimeData?.rows?.[0]?.metricValues?.[1]?.value || 0} />
        </Col>
        <Col span={6}>
          <Statistic title="Page Views" value={realtimeData?.rows?.[0]?.metricValues?.[2]?.value || 0} />
        </Col>
        <Col span={6}>
          <Statistic title="Bounce Rate" value={realtimeData?.rows?.[0]?.metricValues?.[3]?.value || 0} precision={2} />
        </Col>
      </Row>
    </Card>
  )
}
