import { Card, Col, Row, Statistic } from 'antd'
import useGoogleAnayticsRealtimeReport from 'hooks/useGoogleAnayticsRealtimeReport'

export default function RealtimeAnalytics () {
  const { data } = useGoogleAnayticsRealtimeReport({
    dimensions: [{ name: 'country' }],
    metrics: [
      { name: 'activeUsers' }, { name: 'screenPageViews' }, { name: 'conversions' }
    ]
  }, { refetchInterval: 10000 })

  const realtimeData = data?.data || {}

  console.log(realtimeData)

  return (
    <Card title="Realtime Statistics">
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Active Users" value={realtimeData?.rows?.[0]?.metricValues?.[0]?.value || 0} />
        </Col>
        <Col span={8}>
          <Statistic title="Page Views" value={realtimeData?.rows?.[0]?.metricValues?.[1]?.value || 0} />
        </Col>
        <Col span={8}>
          <Statistic title="Conversions" value={realtimeData?.rows?.[0]?.metricValues?.[2]?.value || 0} />
        </Col>
      </Row>
    </Card>
  )
}
